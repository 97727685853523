import { act } from "../../jsx-act";
import SpaceBetween from "../utilities/space-between";
import CookieDefinitions from "../../definitions/cookie-definitions";
import UXDefinitions from "../../definitions/ux-definitions";
import Button from "../utilities/button";
import Modal from "../utilities/modal";
import ColumnLayout from "../utilities/column-layout";
import FormField from "../utilities/form-field";
import RadioGroup from "../utilities/radio-group";
import { CCBA_MODAL_ID } from "../../constants/ux-constants";
import { getConsentCookie as getConsentCookieCore } from "../../cookie";
import "./ccba-selector.scss";
import { DEFAULT_COOKIE } from "../../constants/cookie-constants";

interface CcbaSelectorProps {
  content: UXDefinitions.CcbaSelectorSectionLocalization;
  isRtl: boolean;
  localizedLabelText?: string;
  darkModeEnabled?: boolean;
  runtimeEnvironment?: UXDefinitions.RuntimeEnvironment; // not needed since the component will be the same
  getConsentCookie: () => ReturnType<typeof getConsentCookieCore>
  handleSaveClick: UXDefinitions.HandleSaveClick;
  handleCancelClick: UXDefinitions.HandleCancelClick;
}

export const CcbaSelector = ({
  isRtl,
  getConsentCookie,
  handleSaveClick,
  handleCancelClick,
  content,
  darkModeEnabled = false,
  runtimeEnvironment = "default"
}: CcbaSelectorProps) => {
  const getCookie = () => getConsentCookie() || DEFAULT_COOKIE;

  const getCcbaSelectionFromCookie = () => {
    const cookie = getCookie();
    return cookie["ccba"];
  };

  const updateCcbaSelection = (
    ccbaSelected: string
  ) => {
    const cookie = getCookie()
    cookie["ccba"] = ccbaSelected === "allowed" ? true : false;
    handleSaveClick(cookie, 'ccbaModal');
  };

  const initializeCcbaSelection = () => {
    const allowCcba = getCcbaSelectionFromCookie();
    let ccbaPropertyValue = true;
    if (allowCcba === false) {
      ccbaPropertyValue = false;
    }
    return ccbaPropertyValue;
  };

  let value = initializeCcbaSelection() === true ? "allowed" : "not allowed";

  const handleSaveButtonClick = () => {
    updateCcbaSelection(value);
  };

  return (
    <Modal
      isRTL={isRtl}
      id={CCBA_MODAL_ID}
      size="large"
      onDismiss={handleCancelClick}
      darkModeEnabled={darkModeEnabled}
      footer={
        <div id="ccba-footer" data-testid="ccba-footer">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              data-testid="cancel-button"
              variant="secondary"
              onClick={() => {
                handleCancelClick('ccbaModal');
              }}
            >
              {content["cancel-button"]}
            </Button>
            <Button
              variant="primary"
              onClick={handleSaveButtonClick}
            >
              {content["save-button"]}
            </Button>
          </SpaceBetween>
        </div>
      }
      header={<span id="ccba-header">{content["tittle"]}</span>}
    >
      <ColumnLayout columns={1} borders="horizontal" data-testid="ccba-content" class="awsccc-cbbas-c">
        <SpaceBetween size="m" direction="vertical">
          <p>{content["introduction"]}</p>
          <p>{content["optout-intro"]}</p>
        </SpaceBetween>
        <FormField label={content["optout-label"]}>
          <RadioGroup
            name='ccba-allowed'
            onChange={({ detail }) => {
              value = detail.value
            }}
            initialValue={value}
            items={[
              { value: "allowed", label: content["option1"] },
              { value: "not allowed", label: content["option2"] },
            ]}
          />
        </FormField>
        <SpaceBetween size="s" direction="vertical">
          <p>{content["extra-info-p1"]}</p>
          <p>{content["extra-info-p2"]}</p>
        </SpaceBetween>
      </ColumnLayout>
    </Modal>
  );
};
